import { LoginCallback } from "@okta/okta-react";
import React from "react";
import { Route, Routes } from "react-router-dom";

import AuthRequire from "./components/auth/AuthRequire";
import Home from "./pages/Home";

const AuditStoreViewPage = React.lazy(() => import("./pages/AuditStoreViewPage"));
const StoreExecutionPage = React.lazy(() => import("./pages/StoreExecutionPage"));
const StoreApprovalPage = React.lazy(() => import("./pages/StoreApprovalPage"));
const StoreStyleViewPage = React.lazy(() => import("./pages/StoreStyleViewPage"));
const ActionAuditTrail = React.lazy(() => import("./pages/ActionAuditTrail"));
const AuditTrailStoreLevelPage = React.lazy(() => import("./pages/AuditTrailStoreLevelPage"));
const AuditDashboard = React.lazy(() => import("./pages/AuditDashboard"));
const StoreManagePage = React.lazy(() => import("./pages/StoreManagePage"));
const StoreStyleExclPage = React.lazy(() => import("./pages/StoreStyleExclPage"));
const UpcManagePage = React.lazy(() => import("./pages/UpcManagePage"));

export const AppRoutes = () => {
  return (
    <Routes>
      <Route 
        path="/" 
        element={<AuthRequire children={<Home />} />}
      />
      <Route path="/login/callback" element={<LoginCallback />} />

      <Route
        path="/auditdashboard"
        element={<AuthRequire children={<AuditDashboard />} />}
      />

      <Route
        path="/audit-store-view"
        element={<AuthRequire children={<AuditStoreViewPage />} />}
      />

      <Route
        path="/store-style-view/:type/:store"
        element={<AuthRequire children={<StoreStyleViewPage />} />}
      />

      <Route
        path="/store-approval"
        element={<AuthRequire children={<StoreApprovalPage />} />}
      />

      <Route
        path="/store-execution"
        element={<AuthRequire children={<StoreExecutionPage />} />}
      />

      <Route
        path="/audittrail"
        element={<AuthRequire children={<ActionAuditTrail />} />}
      />

      <Route
        path="/audittrailstorelevel/:store"
        element={<AuthRequire children={<AuditTrailStoreLevelPage />} />}
      />

      <Route
        path="/storestyleexc"
        element={<AuthRequire children={<StoreStyleExclPage/>} />}
      />

      <Route
        path="/storconfig"
        element={<AuthRequire children={<StoreManagePage/>} />}
      />  

      <Route
        path="/upcconfig"
        element={<AuthRequire children={<UpcManagePage/>} />}
      />  

    </Routes>
  );
};
