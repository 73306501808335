import { LicenseInfo } from '@mui/x-license-pro';
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";

LicenseInfo.setLicenseKey('68b374edfcd7fc0317ba68f78bcf1f57Tz01NjAxOCxFPTE3MDI1MDU3MzY3NTMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

//webpack < 5 used to include polyfills for node.js core modules by default.
//This is no longer the case. Verify if you need this module and configure a polyfill for it
//import dotenv from "dotenv";
//dotenv.config();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>
);
