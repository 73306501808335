import { useContext, useState } from "react";
import Scrollbar from "src/components/common/Scrollbar";
import { SidebarContext } from "src/contexts/SidebarContext";
import { useOktaAuth } from "@okta/okta-react";
import {
  Box,
  Drawer,
  alpha,
  styled,
  Divider,
  useTheme,
  Button,
  lighten,
  darken,
  Tooltip,
} from "@mui/material";

import SidebarMenu from "./SidebarMenu";
import Logo from "src/components/common/Logo";
import useAuth from "src/hooks/useAuth";

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const [isOpen, setIsOpen] = useState(false);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();
  const { isAuthenticated, isAuthPending, userInfo, login, logout } = useAuth();

  return (
    <>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
        }}
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={sidebarToggle}
        // onClose={closeSidebar}
        variant="persistent"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            // display: {
            //   xs: 'none',
            //   lg: 'inline-block'
            // },
            position: 'fixed',
            left: 0,
            top: 0,
            background:
              theme.palette.mode === 'dark'
                ? alpha(lighten(theme.header.background, 0.1), 0.5)
                : darken(theme.colors.alpha.black[100], 0.5),
            boxShadow:
              theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
          }}
        >
          <Scrollbar>
            <Box mt={3}>
              <Box
                mx={2}
                sx={{
                  width: 52,
                }}
              >
                <Logo />
              </Box>
            </Box>
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10],
              }}
            />
            <SidebarMenu />
          </Scrollbar>
          <Divider
            sx={{
              background: theme.colors.alpha.trueWhite[10],
            }}
          />
          <Box p={2}>
            {isAuthenticated && (
              <Button
                href=""
                rel="noopener noreferrer"
                variant="contained"
                color="warning"
                size="small"
                onClick={logout}
                fullWidth
              >
                Logout
              </Button>
            )}
            {!isAuthPending && !isAuthenticated && (
              <Button
                href=""
                rel="noopener noreferrer"
                variant="contained"
                color="primary"
                size="small"
                onClick={login}
                fullWidth
              >
                Login
              </Button>
            )}
          </Box>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
