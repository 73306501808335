import { useOktaAuth } from "@okta/okta-react";
import { FC, Fragment, ReactNode } from "react";

type Props = {
  children?: ReactNode
};

const AuthRequire: FC<Props> = ({ children }) => {
  const { oktaAuth, authState } = useOktaAuth();

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  if (authState && !authState?.isAuthenticated) {
    if (!authState?.isPending) {
      triggerLogin();
    }
    return null;
  }

  return <Fragment>{children}</Fragment>;
};

export default AuthRequire;
