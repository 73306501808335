import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { Suspense, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CssBaseline } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import BaseLayout from "src/layouts/BaseLayout";
import SidebarLayout from "src/layouts/SidebarLayout";
import { AppRoutes } from "./AppRoutes";
import AuthRequiredModal from "./components/auth/AuthRequiredModal";
import Loading from "./components/ui/Loading";
import { SidebarProvider } from "./contexts/SidebarContext";
import ThemeProvider from "./theme/ThemeProvider";

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_ISSUER!,
  clientId: process.env.REACT_APP_OKTA_ID!,
  redirectUri: window.location.origin + "/login/callback",
  scopes: ["openid", "profile", "email", "RFIDGrp"],
  pkce: true,
});

function App() {
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = useState(false);
  const history = useNavigate();

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history(toRelativeUrl(originalUri || "/", window.location.origin), {
      replace: true,
    });
  };

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await triggerLogin();
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      setAuthRequiredModalOpen(true);
    }
  };

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <Security
          oktaAuth={oktaAuth}
          onAuthRequired={customAuthHandler}
          restoreOriginalUri={restoreOriginalUri}
        >
          <SidebarProvider>
            <AuthRequiredModal
              {...{
                authRequiredModalOpen,
                setAuthRequiredModalOpen,
                triggerLogin,
              }}
            />
            <BaseLayout>
              <SidebarLayout>
                <Suspense
                  fallback={
                    <div className="center">
                      <Loading />
                    </div>
                  }
                >
                  <AppRoutes />
                </Suspense>
              </SidebarLayout>
            </BaseLayout>
          </SidebarProvider>
        </Security>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
