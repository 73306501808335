import { Box, Button, Grid, Typography } from '@mui/material';
import { useOktaAuth } from "@okta/okta-react";
import React from 'react';

const Home: React.FC = () => {

  const { authState, oktaAuth } = useOktaAuth();

  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };
  const logout = async () => {
    console.log("logging out ..");
    await oktaAuth.signOut();
  };

  const buttonText = authState?.isAuthenticated ? "Logout" : "Login";
  const btnLogic = authState?.isAuthenticated ? logout : login;

  return (
    <div style={{ paddingTop: 50, paddingLeft: 20}}>
    <Box>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Typography variant="h3" fontWeight={700}>
          Collaborative Business Solutions
          </Typography>
          <Typography>Resolutions that help shape and drive our business to the next level.</Typography>
          <Typography>Current tooling provided for consumption.</Typography>
          <Typography style={{paddingLeft: 20, paddingBottom: 20}}>- RFID to Omnia Audit Application.</Typography>
            
          <Button
            variant="contained"
            color="primary"
            sx={{ width: '200px', fontSize: '16px'}}
            onClick={btnLogic}
          >
            {buttonText}
          </Button>

        </Grid>
        <Grid item xs={12} md={5}>
          <img src="/carters.jpg" alt="Carters"  width="500px" height="500px"/> 
        </Grid>
      </Grid>
    </Box>
    </div>
  );
};

export default Home;
