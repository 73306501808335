import { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { UserClaims } from "@okta/okta-auth-js";


const useAuth = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaims>();
  const [userGroup, setUserGroup] = useState<String[]>([]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await oktaAuth.getUser();
        // console.log("user claims: " + JSON.stringify(res))
        setUserInfo(res);  
        //RFIDGrp in preview and RFIDgroup in prod
        setUserGroup(res["RFIDgroup"] as String[] || res["RFIDGrp"] as String[] || []);
      } catch (error) {
        console.log("error while get and set okta user info: " + error);
      }
    };

    authState?.isAuthenticated && getUser();
  }, [authState, oktaAuth]);

  const login = async () => oktaAuth.signInWithRedirect();
  const logout = async () => {
    //   console.log("logging out ..");
    await oktaAuth.signOut();
  };

  const isAuthenticated = authState?.isAuthenticated;
  const isAuthPending = authState?.isPending;
  const accessToken = authState?.accessToken?.accessToken;
  const authHeader = new Headers([
    ["Authorization", accessToken],
  ])
  // userGroup: 
  // 1) RFID Admin;
  // 2) RFID Audit Manager;
  // 3) RFID Audit Analyst;
  // 4) RFID System Manager;
  const isManagerOrAdmin = userGroup?.includes('RFID Admin') || userGroup?.includes('RFID Audit Manager') || false;
  // console.log("userGroup: " + userGroup + ", isManagerOrAdmin: " + isManagerOrAdmin)
  
  return { isAuthenticated, isAuthPending, accessToken, authHeader, userInfo, userGroup, isManagerOrAdmin, login, logout };
};

export default useAuth;
