import { Box, Button, Modal, Typography } from "@mui/material";
import { FC } from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type Props = {
  authRequiredModalOpen: boolean
  setAuthRequiredModalOpen: any
  triggerLogin: any
};

const AuthRequiredModal: FC<Props> = ({
  authRequiredModalOpen,
  setAuthRequiredModalOpen,
  triggerLogin,
}) => {
  const closeModal = () => {
    setAuthRequiredModalOpen(false);
  };

  const confirmModal = () => {
    setAuthRequiredModalOpen(false);
    triggerLogin();
  };

  return (
    <Modal
      onClose={closeModal}
      //   onActionClick={confirmModal}
      open={authRequiredModalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Auth required
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Do you want to re-authenticate?
        </Typography>
        <Button onClick={closeModal}>No</Button>
        <Button onClick={confirmModal}>Yes</Button>
      </Box>
    </Modal>
  );
};
export default AuthRequiredModal;
